import React from "react"

import { link } from "gatsby"

import Layout from "../components/Layout"

import EmbeddedVideo from "../components/misc/EmbeddedVideo"
import CustomButton from "../components/misc/CustomButton"

import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default ({ data }) => {
  return (
    <Layout
      title={
        <div>
          BARSKA
          <br />
          PHOTO
          <br />
          STORIES
        </div>
      }
    >
      <Container>
        <EmbeddedVideo embedId="v1W_xc_wc68" />
        <CustomButton style={{ paddingTop: "10vh", paddingBottom: "10vh" }}>
          <a href="https://calendly.com/barskaphotostories">
            BOOK A FREE MEETING
          </a>
        </CustomButton>
      </Container>
    </Layout>
  )
}
