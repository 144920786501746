import React from "react"
import styled from "styled-components"

const CTAWrapper = styled.div`
  display: flex;
  justify-content: ${({ justify }) => "" + justify};
  align-items: center;
`

const CTAButtonStyle = styled.button`
  margin: 0px 1em 0px 1em;
  padding: 1.25em 2.5em 1.25em 2.5em;
  border-radius: 10em; // just round
  text-transform: uppercase;
  font-size: 1.25em;
  font-family: monument;

  border: none;

  box-shadow: 0.3em 0.3em 0.3em #ccc;
  ${({ noShadow }) => (noShadow ? `box-shadow: none;` : "")}
  background: rgb(245,215,160);
  background: linear-gradient(
    133deg,
    rgba(245, 215, 160, 1) 30%,
    rgba(240, 185, 175, 1) 100%
  );

  ${({ bgColor }) => `background: ${bgColor};`}

  transition: 0.5s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  a {
    color: black;
    font-size: 1em;
    font-family: monument;
    text-decoration: none;
  }
`

const CTAButton = ({
  noShadow = false,
  justify = "center",
  bgColor = null,
  style = {},
  children,
}) => (
  <CTAWrapper justify={justify} style={style}>
    <CTAButtonStyle noShadow={noShadow} bgColor={bgColor}>
      {children}
    </CTAButtonStyle>
  </CTAWrapper>
)

export default CTAButton
